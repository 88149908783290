<template>
  <div class="container">
    <el-container class="container-box">
      <el-header height="75px">
        <div class="box">
          <el-image
            style="
              width: 50px;
              height: 50px;
              padding: 5px;
              background: #ffffff;
              border-radius: 8px;
              float: left;
            "
            src="/pc/logo.png"
          ></el-image>
          <span class="header-t">模型树</span>
          <div class="header-btn" style="width: 550px">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
            >
              <el-menu-item index="0" @click="toPage(0)">首页</el-menu-item>
              <el-menu-item index="1" @click="toPage(1)"
                >下载客户端</el-menu-item
              >
              <el-menu-item index="2" @click="toPage(2)">精彩文章</el-menu-item>
              <el-menu-item index="3" @click="toPage(3)">关于我们</el-menu-item>
              <el-menu-item index="4" style="width: 110px">
                <el-button type="primary" class="menu-login" @click="toLogin">{{
                  loginText
                }}</el-button>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div class="main-title">模型树客户端</div>
          <div class="main-title2">
            您不仅可以在线使用幕布网页版，也可以安装离线客户端，给您一个更安静的学习与工作空间<br />本地存储，云端同步，数据永不丢失，享受更快速的记录体验
          </div>
          <div class="main-title3">手机客户端</div>
          <div class="main-dl">
            <el-image
              style="
                width: 224px;
                height: 224px;
                padding: 6px;
                background: #ffffff;
                border-radius: 8px;
                float: left;
              "
              src="/pc/qr-download.png"
            ></el-image>
            <div class="dl-right">
              <div style="margin-top: 20px">
                <el-button class="dl-btn"
                  ><span class="el-icon-my-apple"></span>iPhone客户端
                </el-button>
              </div>
              <div>
                <el-button class="dl-btn"
                  ><span class="el-icon-my-android"></span>Android客户端
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-box">
          <div class="des-box">
            <el-descriptions class="margin-top" direction="vertical">
              <el-descriptions-item label="模型树">
                <div style="font-size: 16px; line-height: 22px; color: #666666">
                  关于我们
                </div>
                <div class="des-info">用户协议</div>
                <div class="des-info">隐私声明</div>
              </el-descriptions-item>
              <el-descriptions-item label="联系客服">
                <div class="des-kf-i">
                  扫码联系微信在线客服<br />（周一至周五9：30-16：30）
                </div>
                <el-image src="/pc/wx-kf.png" class="footer-img-1"> </el-image>
              </el-descriptions-item>
              <el-descriptions-item label="微信公众号">
                <div class="des-gzh-i">
                  想要了解更多精彩文章，欢迎关注以下微信公众号
                </div>
                <div>
                  <div class="block">
                    <el-image
                      src="/pc/wx-gzh-1.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">思维有了模型</span>
                  </div>
                  <div class="block">
                    <el-image
                      src="/pc/wx-gzh-2.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">学习有了方法</span>
                  </div>
                  <div class="block" style="margin-right: 0">
                    <el-image
                      src="/pc/wx-gzh-3.png"
                      class="footer-img-2"
                    ></el-image>
                    <span class="demonstration">逑知学研院</span>
                  </div>
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="copyright">
            Copyright ©2021 逑知（深圳）教育科技有限公司<br /><a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              >粤ICP备2021136613号</a
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      isLogin: false,
      loginText: "注册/登录",
    };
  },
  created() {
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin == "true") {
      this.loginText = "已登录";
    } else {
      this.loginText = "注册/登录";
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toPage(i) {
      if (i == 0) {
        this.$router.push("/");
      } else if (i == 1) {
        this.$router.push("/download");
      } else if (i == 2) {
        this.$router.push("/articles");
      } else if (i == 3) {
        this.$router.push("/about");
      }
    },
    toLogin() {
      // this.$api.article.articleTree().then((res) => {
      //   // 保存token
      //   this.$store.commit("setToken", "k2CQGneAdNkJext6O8NiOYW4uKcqsAxyJQmZPxldGjTeW2Kw1cC3OTQFp2ZT4C64");
      //   // 读取token
      //   console.log(this.$store.state.mindmap_token);
      // });
      if (this.isLogin == "true") {
        this.$router.push("/article");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style src="@/assets/bootstrap/css/bootstrap.min.css" scoped></style>
<style scoped>
.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background: #f2f2f2;
}
.container-box {
  width: 1080px;
  margin: 0 auto;
}
.el-header {
  padding: 0;
}
.el-menu {
  background-color: transparent !important;
  border: 0 !important;
}
.box {
  width: 1080px;
  margin: 8px auto;
  text-align: center;
}
.header-t {
  float: left;
  margin-left: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 60px;
  color: #1380ff;
}
.header-btn {
  float: right;
}
.menu-login {
  border-radius: 20px;
  line-height: 1px;
}
.el-button {
  min-height: 32px !important;
}
.el-menu-item {
  background-color: transparent !important;
  border: 0 !important;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: transparent !important;
  border: 0 !important;
}
.el-menu-item.is-active {
  border: 0 !important;
  font-weight: 900 !important;
  color: #333333 !important;
}
.main-title {
  margin-top: 95px;
  margin-bottom: 13px;
  font-weight: 900;
  font-size: 56px;
  color: #333333;
}
.main-title2 {
  margin-bottom: 50px;
  font-size: 24px;
  line-height: 56px;
  color: #666666;
}
.main-tabs {
  margin: 0 auto;
  margin-bottom: 64px;
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}
.main-tab-l {
  float: left;
  width: 120px;
  height: 40px;
  color: #ffffff;
  background: #1380ff;
  border-radius: 8px 0px 0px 8px;
}
.main-tab-r {
  float: right;
  width: 120px;
  height: 40px;
  color: #1380ff;
}
.el-icon-my-tree {
  background: url("~@/assets/icon-tree-white.png") no-repeat center center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  /* background-size: cover;*/
}
.el-icon-my-tree:before {
  content: "树";
  font-size: 16px;
  visibility: hidden;
}
.el-icon-my-tree {
  font-size: 16px;
  margin-right: 10px;
}
.el-icon-my-tree:before {
  content: "\e611";
}
.el-icon-my-mind {
  background: url("~@/assets/icon-mind.png") no-repeat center center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  /* background-size: cover;*/
}
.el-icon-my-mind:before {
  content: "思维导图";
  font-size: 16px;
  visibility: hidden;
}
.el-icon-my-mind {
  font-size: 16px;
  margin-right: 10px;
}
.el-icon-my-mind:before {
  content: "\e611";
}
.el-icon-my-android {
  background: url("~@/assets/icon-android.png") no-repeat center center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  /* background-size: cover;*/
}
.el-icon-my-android:before {
  content: "树";
  font-size: 16px;
  visibility: hidden;
}
.el-icon-my-android {
  font-size: 16px;
  margin-right: 10px;
}
.el-icon-my-android:before {
  content: "\e611";
}
.el-icon-my-apple {
  background: url("~@/assets/icon-apple.png") no-repeat center center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  /* background-size: cover;*/
}
.el-icon-my-apple:before {
  content: "树";
  font-size: 16px;
  visibility: hidden;
}
.el-icon-my-apple {
  font-size: 16px;
  margin-right: 10px;
}
.el-icon-my-apple:before {
  content: "\e611";
}
.main-lp {
  width: 1080px;
}
.box-lp {
  width: 1280px;
  margin: -12px auto 0;
}
.box-lp-1 {
  width: 100%;
  height: 16px;
  background: #d8d8d8;
  border-radius: 2px 2px 0px 0px;
}
.box-lp-2 {
  width: 100%;
  height: 8px;
  background: #b5b5b5;
  border-radius: 0px 0px 160px 160px;
}
.class-box {
  margin-top: 160px;
  margin-bottom: 130px;
  width: 1080px;
  height: 280px;
  background: #ffffff;
  box-shadow: 4px 4px 0px #c8c8c8;
  border-radius: 8px;
}
.class-box-l {
  float: left;
  margin: 20px 94px 0 23px;
  width: 400px;
  height: 240px;
  background: linear-gradient(180deg, #d64e8c 0%, #040969 100%);
  border-radius: 8px;
}
.class-box-l-t {
  margin-top: 61px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 56px;
  /* identical to box height, or 127% */
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 0px 0px rgba(3, 33, 39, 0.64);
}
.class-box-l-d {
  width: 300px;
  margin: 40px 51px 0;
}
.class-box-l-d-v {
  float: left;
  width: 72px;
}
.class-box-l-d-t {
  float: left;
  width: 156px;
  font-weight: 900;
  font-size: 28px;
  color: #ffffff;
  background: transparent;
}
.class-box-r {
  float: left;
  text-align: left;
}
.class-box-r-t {
  margin: 30px 0 15px;
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  color: #333333;
}
.class-box-r-i {
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  color: #666666;
}
.class-box-r-btn {
  margin-top: 15px;
  width: 200px;
  line-height: 15px;
  background: #1380ff;
  border-radius: 30px;
  font-size: 20px;
}
.main-title3 {
  margin-top: 70px;
  font-weight: 900;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 4px;
  text-align: center;
  color: #333333;
}
.footer-box {
  margin: 90px auto 0;
  width: 100%;
  height: 370px;
  background: #f7f7f7;
}
.des-box {
  width: 968px;
  margin: 0 auto;
  padding-top: 70px;
}
.des-info {
  margin-top: 30px;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
.footer-img-1 {
  width: 80px;
  height: 80px;
  background: #d8d8d8;
}
.footer-img-2 {
  width: 96px;
  height: 96px;
  background: #d8d8d8;
}
.des-kf-i {
  margin-bottom: 5px;
  color: #666666;
}
.des-gzh-i {
  margin-bottom: 5px;
  color: #666666;
}
.block {
  width: 96px;
  height: 96px;
  margin-top: 12px;
  margin-right: 10px;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.demonstration {
  margin-top: 12px;
  display: block;
  color: #666666;
  font-size: 12px;
  text-align: center;
}
.el-descriptions >>> .el-descriptions__label {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #666666;
}
.el-descriptions >>> .el-descriptions-item__label.has-colon::after {
  content: "";
}
.el-descriptions >>> .el-descriptions__body {
  background: transparent !important;
}
.copyright {
  margin-top: 70px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  color: #666666;
}
.main-dl {
  width: 560px;
  height: 240px;
  margin: 70px auto 150px auto;
}
.dl-right {
  float: right;
}
.dl-btn {
  width: 240px;
  margin: 25px 0;
  border: 3px solid #1380ff;
  box-sizing: border-box;
  border-radius: 80px;
}
</style>
